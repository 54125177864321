<template>
  <div>
    <AppLoadingSpinner v-model="isLoading" />
    <v-card max-width="600px" class="mx-auto mb-6 px-2 py-1" elevation="6">
      <v-card-title class="mt-4">{{ `${personName}，你好!` }}</v-card-title>
      <v-divider></v-divider>
      <div v-if="showSelfPickPanel">
        <v-card-title class="mt-4">自选测评</v-card-title>
        <v-card-text>
          <v-subheader>
            点击选择需要测量的量表（可多选）。量表的答题顺序就是点击选中的顺序。
          </v-subheader>
          <v-list>
            <v-list-item-group
              color="primary"
              multiple
              v-model="selectedLbGuids"
            >
              <v-list-item
                v-for="lb in selfPickLbList"
                :key="lb.lbGuid"
                :value="lb.lbGuid"
                :disabled="isStatusCompleted(lb.status)"
                class="mt-2"
              >
                {{ `${lb.lbDispName} ${getLbStatusText(lb.status)}` }}
                <v-spacer></v-spacer>
                {{ getLbOrderText(lb.lbGuid) }}
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="px-4"
            depressed
            @click="confirmOpenLbGroup"
            :disabled="!selectedLbGuids.length"
          >
            选好了
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </div>
      <div>
        <v-card-title class="mt-4">待完成的测评</v-card-title>
        <v-card-text>
          <v-subheader>
            以下是您待完成的量表，可直接点击“开始此项测评”开始。
          </v-subheader>
          <v-expansion-panels
            accordion
            multiple
            v-model="lbGroupStatusPanelVal"
          >
            <v-expansion-panel
              v-for="lbGroup in lbGroupStatusList"
              :key="lbGroup.guid"
            >
              <div v-if="!isAllStatusCompleted(lbGroup.lbStatusList)">
                <v-expansion-panel-header>
                  <span>{{ lbGroup.lbGroupName }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-list>
                    <v-list-item
                      v-for="lb in lbGroup.lbStatusList"
                      :key="lb.lbGuid"
                      :disabled="isStatusCompleted(lb.status)"
                    >
                      {{ lb.lbDispName }}
                      <v-spacer></v-spacer>
                      {{ getLbStatusText(lb.status) }}
                    </v-list-item>
                  </v-list>
                  <div class="text-right">
                    <v-btn
                      color="primary"
                      depressed
                      @click="
                        pickOneGroupLbGroup(lbGroup.guid, lbGroup.lbStatusList)
                      "
                    >
                      开始此项测评
                    </v-btn>
                  </div>
                </v-expansion-panel-content>
              </div>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </div>
      <div>
        <v-card-title class="mt-4">已完成的测评</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item
              v-for="testCase in completedTestList"
              :key="testCase.guid"
            >
              <v-list-item-content>
                <v-list-item-title class="mb-2">
                  {{ testCase.lbDispName }}
                </v-list-item-title>
                <v-list-item-subtitle v-if="testCase.lbGroupName" class="mb-1">
                  <v-icon small>mdi-clipboard-check-multiple-outline</v-icon>
                  {{ testCase.lbGroupName }}
                </v-list-item-subtitle>
                <v-list-item-subtitle>
                  <v-icon small>mdi-calendar-check</v-icon>
                  {{ testCase.testDate }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="testCase.isReportAvailable">
                <v-btn
                  icon
                  color="primary"
                  @click="
                    showCaseReport({
                      caseGuid: testCase.guid,
                      lbId: testCase.lbId,
                      lbDispName: testCase.lbDispName
                    })
                  "
                >
                  <v-icon>mdi-file-document-outline</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </div>
    </v-card>
    <AppMessageBox v-model="errorMsg" title="发生错误" />
  </div>
</template>

<script>
import AppLoadingSpinner from "@/components/AppLoadingSpinner";
import AppMessageBox from "@/components/AppMessageBox";
import { mapActions, mapGetters } from "vuex";
import {
  fetchPersonInfoInternal,
  fetchLbGroupStatusList,
  fetchOpenLbGroupStatus,
  fetchIsContinuousTest,
  fetchCompletedTestList
} from "@/api/sca";
import { caseStatusDict } from "@/utils/constants/case";

export default {
  components: {
    AppLoadingSpinner,
    AppMessageBox
  },

  data() {
    return {
      isLoading: false,
      errorMsg: "",
      personName: "",
      lbGroupStatusList: [],
      selfPickLbList: [],
      lbGroupTodoList: [],
      completedTestList: [],
      lbGroupStatusPanelVal: [],
      selectedLbGuids: [],
      selectedLbList: [],
      selectedLbGroupGuid: ""
    };
  },

  computed: {
    ...mapGetters({
      // 登录的 personGuid
      personGuid: "sca/personGuid",
      // OpenLbGroup 的 Guid
      openLbGroupGuid: "sca/openLbGroupGuid"
    }),
    showSelfPickPanel() {
      return this.selfPickLbList && this.selfPickLbList.length;
    }
  },

  methods: {
    ...mapActions({
      setShowReportCaseList: "sca/setShowReportCaseList"
    }),
    async getPersonInfoInternal() {
      try {
        let personInfoFound = await fetchPersonInfoInternal(this.personGuid);
        this.personName = personInfoFound.name;
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async getLbGroupStatusList() {
      try {
        // 添加团体测评列表
        this.lbGroupStatusList = await fetchLbGroupStatusList(this.personGuid);
        // 添加连续测试的开放测量
        if (this.openLbGroupGuid) {
          let openLbGroupStatusList = await fetchOpenLbGroupStatus(
            this.openLbGroupGuid,
            this.personGuid
          );
          if (openLbGroupStatusList && openLbGroupStatusList.length) {
            let isContTest = await fetchIsContinuousTest(this.openLbGroupGuid);
            if (isContTest) {
              this.lbGroupStatusList.push(openLbGroupStatusList[0]);
            } else {
              this.selfPickLbList.push(
                ...openLbGroupStatusList[0].lbStatusList
              );
            }
          }
        }
        // 默认把所有的 ExpansionPanel 都打开
        this.lbGroupStatusPanelVal = [...[...this.lbGroupStatusList].keys()];
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    async getCompletedTestList() {
      try {
        this.completedTestList = await fetchCompletedTestList(this.personGuid);
      } catch (err) {
        this.errorMsg = err.message;
      }
    },
    isStatusCompleted(lbStatus) {
      if (lbStatus && lbStatus.name) {
        return lbStatus.name === caseStatusDict.completed.value;
      }
      return false;
    },
    isAllStatusCompleted(lbStatusList) {
      if (lbStatusList && lbStatusList.length) {
        let incompletedIdx = lbStatusList.findIndex(
          ls => !ls.status || ls.status.name !== caseStatusDict.completed.value
        );
        return incompletedIdx < 0;
      }
      return true;
    },
    getLbStatusText(lbStatus) {
      if (lbStatus && lbStatus.name && caseStatusDict[lbStatus.name]) {
        return caseStatusDict[lbStatus.name].text;
      }
      return "";
    },
    getLbOrderText(lbGuid) {
      let selectedIndex = this.selectedLbGuids.indexOf(lbGuid);
      if (selectedIndex > -1) {
        return `答题顺序: ${selectedIndex + 1}`;
      }
      return "";
    },
    confirmOpenLbGroup() {
      this.selectedLbList = this.selectedLbGuids
        .map(lbGuid => this.selfPickLbList.find(lb => lb.lbGuid === lbGuid))
        .filter(lb => lb);
      this.selectedLbGroupGuid = this.openLbGroupGuid;
      this.startLbTests();
    },
    pickOneGroupLbGroup(lbGroupGuid, lbStatusList) {
      if (lbStatusList && lbStatusList.length) {
        this.selectedLbList = lbStatusList
          // completed 的不包含在内
          .filter(
            lb =>
              !lb.status || lb.status.name !== caseStatusDict.completed.value
          );
        this.selectedLbGroupGuid = lbGroupGuid;
        this.startLbTests();
      }
    },
    startLbTests() {
      // 重新设定 testLbList
      this.$store.dispatch("sca/setTestLbList", this.selectedLbList);
      this.$store.dispatch("sca/setLbGroupGuid", this.selectedLbGroupGuid);
      this.$router.push({ name: "scastepper" });
    },
    showCaseReport({ caseGuid, lbId, lbDispName }) {
      if (caseGuid) {
        this.setShowReportCaseList([{ caseGuid, lbId, lbDispName }]);
        this.$router.push({ name: "scareport" });
      }
    }
  },

  async created() {
    this.isLoading = true;
    await this.getPersonInfoInternal();
    await this.getLbGroupStatusList();
    await this.getCompletedTestList();
    this.isLoading = false;
  }
};
</script>
